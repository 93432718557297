import {Component} from "react";
import {fromVersionChecker} from "../../../Common/Lib/versionCheck";

export default class StatsRow extends Component {
    render() {
        const showTotal = this.props.showTotal || false
        const fromVersion = fromVersionChecker(this.props.currentVersion)
        const content = (
            <tr className={'amr-stats-row'}>
            <td width={'40%'} align={"right"}>
                <div className="progress" style={{transform: 'rotate(180deg)', width: '80%'}}>
                    <div className={'progress-bar bg-blue'}
                         style={{width: `${Math.ceil((this.props.p1.value / (this.props.p1.total || 1)) * 100)}%`}}><span
                        style={{transform: 'rotate(180deg)'}}>{`${Math.ceil((this.props.p1.value / (this.props.p1.total || 1)) * 100)}%`}</span>
                    </div>
                </div>
            </td>
            <td width={'5%'} align={"right"}>{this.props.p1.value}{showTotal ? `/${this.props.p1.total}` : ''}</td>
            <td width={'10%'} align={"center"}><span className={this.props.tooltip ? 'amr-tooltip amr-tooltip-label' : null}><span className={'amr-stats-label'}>{this.props.label}</span>{this.renderTooltipText()}</span></td>
            <td width={'5%'} align={"left"}>{this.props.p2.value}{showTotal ? `/${this.props.p2.total}` : ''}</td>
            <td width={'40%'} align={"left"}>
                <div className="progress" style={{width: '80%'}}>
                    <div className={'progress-bar bg-blue'}
                         style={{width: `${Math.ceil((this.props.p2.value / (this.props.p2.total || 1)) * 100)}%`}}>{`${Math.ceil((this.props.p2.value / (this.props.p2.total || 1)) * 100)}%`}</div>
                </div>
            </td>
        </tr>)
        return fromVersion(this.props.fromVersion) ? content : null
    }

    renderTooltipText() {
        return this.props.tooltip ? (<span className="amr-tooltiptext">{this.props.tooltip}</span>) : null
    }
}