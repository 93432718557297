import {Component} from "react";
import moment from "moment";
import withRouter from '../../Common/Component/withRouter';
import Api from "../../Common/Lib/Api";
import PlayerImageSvg from "../../Common/PlayerImageSvg";
import GeneralTab from "./Details/GeneralTab";
import {fromVersionChecker} from "../../Common/Lib/versionCheck";
import ServeReturnTab from "./Details/ServeReturnTab";
import ScoresErrorsTab from "./Details/ScoresErrorsTab";
import PointsDistribution from "./Details/PointsDistribution";
import PointByPointTab from "./Details/PointByPointTab";

class Details extends Component {
    constructor(props) {
        super(props);
        this.copyLink.bind(this)
    }

    state = {
        isLoaded: false,
        match: undefined,
        currentTab: 'general',
        stats: ['match'],
        error: '',
        shareText: 'Share Link'
    }

    copyLink(e) {
        e.preventDefault()
        const url = `${window.location.origin}/guest/match/${this.state.match.publicId}`
        navigator.clipboard.writeText(url);
        this.setState({shareText: 'Copied!'})
        setTimeout(() => {
            this.setState({shareText: 'Share Link'})
        },1000)
    }

    createShareButton() {
        if(!this.props.isGuest) {
            return (
                <ul className={'navbar-nav ml-auto'}>
                    <li className={"nav-item"}><button className={"btn btn-primary"} onClick={(e) => this.copyLink(e)}>{this.state.shareText}</button>
                    </li>
                </ul>
            )
        }
    }

    async componentDidMount() {
        const {matchId} = {...this.props.params}
        try {
            const url = this.props.isGuest ? `match/guest/${matchId}`: `match/${matchId}`
            const match = await Api.get(url)
            if(match.statusCode === 404) {
                this.setState({
                    isLoaded: true,
                    error: 'not found'
                })
            } else {
                if (match) {
                    const currentTab = fromVersionChecker(match.version)('1.0.0') ? 'general' : 'general'
                    this.setState({
                        isLoaded: true,
                        match,
                        currentTab
                    })
                }
            }
        } catch (e) {
            this.setState({
                isLoaded: true,
                match: undefined,
            })
        }
    }

    render() {
        const wrapperClassname = this.props.isGuest ? '' : 'content-wrapper'
        if (!this.state.isLoaded) {
            return (<div className={wrapperClassname}>
                <div className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <center>...loading...</center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        } else {
            if (!this.state.match) {
                return (<div className={wrapperClassname}>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <center>No Data</center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }
        }

        const getPlayerStats = () => {
            if(this.state.stats[0] === 'match') {
                return {
                    p1Stats: this.state.match.playerStats.p1Stats.match,
                    p2Stats: this.state.match.playerStats.p2Stats.match,
                }
            }
            if(this.state.stats[0] === 'set') {
                return {
                    p1Stats: this.state.match.playerStats.p1Stats.sets[this.state.stats[1]],
                    p2Stats: this.state.match.playerStats.p2Stats.sets[this.state.stats[1]],
                }
            }
        }

        const fromVersion = fromVersionChecker(this.state.match.version)

        const secondsToDuration = (secondsToCalculate) => {
            let hours = 0;
            let minutes = 0;
            let seconds = 0;
            if(secondsToCalculate > 0) {
                hours = Math.floor(secondsToCalculate/3600)
                minutes = Math.floor((secondsToCalculate-(hours*3600)) / 60)
                seconds = secondsToCalculate-minutes*60-hours*3600
                console.log(hours, minutes, seconds, secondsToCalculate)
            }
            return {
                hours,
                minutes,
                seconds,
            }
        }

        const matchDuration = secondsToDuration(this.state.match.duration)
        const warmupDuration = fromVersion('1.16.0') ? secondsToDuration(this.state.match.warmupDuration) : 0

        const playerStats = fromVersion('1.0.0') ? getPlayerStats() : {}

        return (<div className={wrapperClassname}>
            <div className="content">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                {this.props.isGuest ? '' : (<h1 className="m-0">Match Details</h1>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <div className={'card-header'}>
                            <nav className={'navbar'}>
                                <ul className={"nav nav-pills"}>
                                    {fromVersion('1.0.0') ? this.renderMatchDropdown() : null}
                                    <li className={"nav-item"}><span onClick={e => {e.preventDefault(); this.setState({currentTab: 'general'})}} className={`nav-link ${this.state.currentTab === 'general' ? 'active' : null}`} style={{cursor: 'pointer'}}>General</span></li>
                                    {fromVersion('1.0.0') ? (<li className={"nav-item"}><span onClick={e => {e.preventDefault(); this.setState({currentTab: 'serveReturn'})}} className={`nav-link ${this.state.currentTab === 'serveReturn' ? 'active' : null}`} style={{cursor: 'pointer'}}>Serve / Return</span></li>) : null}
                                    {fromVersion('1.0.0') ? (<li className={"nav-item"}><span onClick={e => {e.preventDefault(); this.setState({currentTab: 'scoresErrors'})}} className={`nav-link ${this.state.currentTab === 'scoresErrors' ? 'active' : null}`} style={{cursor: 'pointer'}}>Scores / Errors</span></li>) : null}
                                    {fromVersion('1.0.0') ? (<li className={"nav-item"}><span onClick={e => {e.preventDefault(); this.setState({currentTab: 'pointsDistribution'})}} className={`nav-link ${this.state.currentTab === 'pointsDistribution' ? 'active' : null}`} style={{cursor: 'pointer'}}>Points Distribution</span></li>) : null}
                                    {fromVersion('1.0.0') ? (<li className={"nav-item"}><span onClick={e => {e.preventDefault(); this.setState({currentTab: 'pointByPoint'})}} className={`nav-link ${this.state.currentTab === 'pointByPoint' ? 'active' : null}`} style={{cursor: 'pointer'}}>Point By Point</span></li>) : null}
                                </ul>
                                {this.createShareButton()}
                            </nav>
                        </div>
                        <div className={'card-body'}>
                            <div className={'row'}>
                                <div style={{
                                    'textAlign': 'right',
                                    'alignSelf': 'center',
                                    'flex': '0 0 38%',
                                    maxWidth: '38%'
                                }}>
                                    <div style={{display: 'inline-block', textAlign: 'center'}}>
                                        <PlayerImageSvg hash={this.state.match.player}
                                                        className={'img-fluid profile-user-img borderless'}/>
                                        <div>{this.state.match.player}</div>
                                    </div>
                                    {this.state.match.isDouble ?
                                        <div style={{display: 'inline-block', textAlign: 'center', margin: '0 15px'}}>
                                            <PlayerImageSvg hash={this.state.match.doublePartner}
                                                            className={'img-fluid profile-user-img borderless'}/>
                                            <div>{this.state.match.doublePartner}</div>
                                        </div>
                                        : undefined}
                                </div>
                                <div style={{
                                    'textAlign': 'center',
                                    'alignSelf': 'center',
                                    'flex': '0 0 24%',
                                    maxWidth: '24%'
                                }}>
                                <p style={{textAlign: 'center'}}>{moment(this.state.match.matchDate).format('YYYY-MM-DD')}</p>
                                    {fromVersion('1.16.0') && this.state.match.warmupDuration ? (<p>Warm up: {(Math.floor(warmupDuration.hours)*60) + Math.floor(warmupDuration.minutes)}m {Math.floor(warmupDuration.seconds)}s </p>) : null}
                                    {fromVersion('1.13.0') && this.state.match.duration ? (<p>Match Duration: {matchDuration.hours}h {Math.floor(matchDuration.minutes)}m </p>) : null}
                                    <p className={'card-result'}><strong>{this.state.match.details.setsWon} - {this.state.match.details.setsLost}</strong></p>
                                    <p>{this.state.match.rawResult}</p>
                                </div>
                                <div style={{
                                    'textAlign': 'left',
                                    'alignSelf': 'center',
                                    'flex': '0 0 38%',
                                    maxWidth: '38%'
                                }}>
                                    <div style={{display: 'inline-block', textAlign: 'center'}}>
                                        <PlayerImageSvg hash={this.state.match.opponentHash}
                                                        className={'img-fluid profile-user-img borderless'}/>
                                        <div>{this.state.match.opponent}</div>
                                    </div>
                                    {this.state.match.isDouble ?
                                    <div style={{display: 'inline-block', textAlign: 'center', margin: '0 15px'}}>
                                        <PlayerImageSvg hash={this.state.match.doubleOpponent}
                                                        className={'img-fluid profile-user-img borderless'}/>
                                        <div>{this.state.match.doubleOpponent}</div>
                                    </div>
                                        : undefined }
                                </div>
                            </div>
                        </div>

                        <div className="card-body table-responsive p-0">
                            <div className={'tab-content'}>
                                {this.renderTab({playerStats})}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    renderTab({playerStats}) {
        switch(this.state.currentTab) {
            case 'serveReturn':
                return <ServeReturnTab match={this.state.match} playerStats={playerStats}/>
            case 'scoresErrors':
                return <ScoresErrorsTab match={this.state.match} playerStats={playerStats}/>
            case 'pointsDistribution':
                return <PointsDistribution match={this.state.match} playerStats={playerStats}/>
            case 'pointByPoint':
                return <PointByPointTab match={this.state.match}/>
            case 'general':
            default:
                return <GeneralTab match={this.state.match} playerStats={playerStats}/>
        }
    }
    renderMatchDropdown() {
        const dropdownLabels = [
            {
                label: 'Match',
                stat: 'match',
            },
            ...(Object.keys(this.state.match.playerStats.p1Stats.sets).map(key => ({
                label: `Set ${key}`,
                stat: 'set',
                number: key
            })))]
        const dirtyLabel = this.state.stats.join(' ')
        const currentLabel = dirtyLabel.charAt(0).toUpperCase() + dirtyLabel.slice(1)
        return (
        <li className="nav-item dropdown">
            <span className="nav-link dropdown-toggle" data-toggle="dropdown">
                {currentLabel} <span className="caret"></span>
            </span>
            <div className="dropdown-menu">
                {dropdownLabels.map(item => {
                    const isHighlighted = this.state.stats.join('') === [item.stat, item.number].join('')
                    const classes = ['dropdown-item']
                    if(isHighlighted) {
                        classes.push('active')
                    }
                    return (<button className={classes.join(' ')} onClick={(e) => {
                    this.setState({
                        stats: [item.stat, item.number]
                    })
                }} key={item.label} tabIndex="-1">{item.label}</button>)
                })}
            </div>
        </li>)
    }
}

export default withRouter(Details)